import React from 'react'
import { Helmet } from 'react-helmet'
import './styles/imoveis.scss'
import { graphql } from 'gatsby'
import Layout from '../components/Layout/index'
import './styles/carreiras.scss'
import { GatsbyImage } from "gatsby-plugin-image";
import Dinamismo from '../images/carreiras/Dinamismo.svg'
import Oportunidade from '../images/carreiras/oportunidade.svg'
import Desafio from '../images/carreiras/desafio.svg'
import Ambiente from '../images/carreiras/ambiente-colaborativo.svg'
import iconLinkedin from '../images/carreiras/linkedin.svg'

export default function Carreiras (props) {
  const breadC = {
    visible: true,
    color: 'black',
    items: [
      { path: '/', name: 'Home' },
      { path: '/carreiras/', name: 'Carreiras' }
    ]
  }
  return (
    <Layout breadCrumb={breadC}>
      <Helmet>
        <html lang="pt-BR" amp />
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
        <title>Carreiras - Dia Supermercados</title>
        <meta name="description" content="Esteja conosco na transformação cultural que impacta positivamente a trajetória profissional dos nossos quase 8.000 colaboradores!" />
        <meta name="keywords" content="trabalhe conosco, carreiras, oportunidades" />
        <meta name="author" content="DIA Supermercados" />
      </Helmet>
      <section>
        <div className="bg-carreiras">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 text-lg-left text-center mt-5 mb-lg-0 mb-2 pt-lg-4">
                <h1 className="text-red font-size-42px" tabIndex={0}>Uma empresa de Relacionamento Humano</h1>
              </div>
              <div className="col-2" />
              <div className="col-lg-6 col-12">
                <GatsbyImage
                  image={props.data.img1.childImageSharp.gatsbyImageData}
                  className="img1 w-100 border-radius-18 mb-lg-0 mb-3" />
              </div>
            </div>
          </div>
        </div>
        <div className="container mt-lg-5">
          <div className="row">
            <div className="col-lg-6 paragrafoRelacoes">
              <p className="mt-lg-0 mt-3 font-size-12">
                As <b>relações humanas</b> estão presentes o tempo todo em nossa vida, seja nas relações pessoais ou nas profissionais. No DIA, seja nas lojas, CDs ou escritórios, além de oferecermos <b>preços baixos, proximidade, marca própria e produtos fresquinhos,</b> somos uma empresa que <b>cria experiências positivas,</b> tanto para nossos clientes quanto para nossos colaboradores, com <b>gente cuidando de gente.</b>
              </p>
            </div>
          </div>
          <div className="row mt-5 mb-5 ">
            <div className="col-lg-6 col-12 d-flex justify-content-lg-end justify-content-center">
              <div className="h-100 " style={{ minHeight: '45px' }}>
                <a href="https://diabrasil.pandape.com.br/" target="_blank" rel="noreferrer"><button id='button-darkmode' className="btn btn-red-1 w-auto h-100 font-size-13" ><span>Veja todas as oportunidades</span></button></a>
              </div>
            </div>
            <div className="col-lg-6 col-12 mt-lg-0 mt-3 d-flex justify-content-lg-start justify-content-center" >
              <div className="h-100" style={{ minHeight: '45px' }}>
                <a href="https://br.linkedin.com/company/dia-brasil" target="_blank" rel="noreferrer"><button className="btn btn-linkedin w-auto font-size-13"><img src={iconLinkedin} alt="LInkedin" className="align-text-top mr-2" /> Siga-nos no Linkedin</button></a>
              </div>
            </div>
          </div>
          <div className="row mb-5">
            <div className="col-lg-6 col-12">
              <GatsbyImage
                image={props.data.img2.childImageSharp.gatsbyImageData}
                className="w-100 border-radius-18 mb-lg-0 mb-3" />
            </div>
            <div className="col-lg-6">
              <p className="mb-3 font-size-12">
                Somos movidos pela <b>paixão em atender nossos clientes</b> com dedicação e excelência. Acreditamos no <b>espírito de colaboração,</b> trabalhando juntos para alcançar os melhores resultados. <b>Nosso compromisso</b> é sempre entregar soluções simples e eficazes, com foco no que realmente importa. Buscamos, a cada dia, <b>o crescimento contínuo,</b> aprendendo e evoluindo para oferecer o melhor a todos ao nosso redor.
              </p>
              <p className="font-size-15"><b>Esteja conosco na transformação cultural que impacta positivamente a trajetória profissional dos nossos quase 2.000 colaboradores!</b></p>
            </div>
          </div>
        </div>
        {/* <div className="bg-ambiente">
          <div className="container">
            <div className="row pt-5 pb-lg-0 pb-5">
              <div className="col-lg-3 col-12 pt-lg-4 mb-lg-0 mb-3">
                <h2 tabIndex={0} className="text-white text-lg-left text-center txt-ambiente">
                  Nosso ambiente
                </h2>
              </div>
              <div className="col-lg-2 col-6 text-center">
                <img src={Dinamismo} alt="Icone relógio" className="w-75 mb-2" />
                <p className="text-white mb-lg-0 mb-4 font-size-1">Dinâmico</p>
              </div>
              <div className="col-lg-2 col-6 text-center">
                <img src={Ambiente} alt="Icone mercearia" className="w-75 mb-2" />
                <p className="text-white mb-lg-0 mb-4 font-size-1">Colaborativo</p>
              </div>
              <div className="col-lg-2 col-6 text-center">
                <img src={Oportunidade} alt="Icone oportunidades" className="w-75 mb-2" />
                <p className="text-white mb-lg-0 mb-4 font-size-1 ">Oportunidades</p>
              </div>
              <div className="col-lg-2 col-6 text-center">
                <img src={Desafio} alt="Icone bandeira" className="w-75 mb-2" />
                <p className="text-white mb-lg-0 mb-4 font-size-1">Desafiador</p>
              </div>
            </div>
          </div>
        </div> */}
        <div className="container">
          <div className="row pt-5 pb-lg-0 pb-5">
            <div className="col-12">
              <GatsbyImage
                image={props.data.imgValoresDia.childImageSharp.gatsbyImageData}
                className="w-100 border-radius-18 mb-lg-0 mb-3"
              />
            </div>
          </div>
        </div>
        <div className="container mb-5" id="oportunidades">
          <h2 tabIndex={0} className="text-center text-red mt-5 mb-5 ">Oportunidades</h2>
          <div className="row justify-content-center">
            <div className="col-lg-3 mb-lg-0 mb-3">
              <div className="oportunidades">
                <a href="https://diabrasil.pandape.com.br/" target="_blank" rel="noopener noreferrer">
                  <h4 className="text-uppercase text-red text-center mt-4 mb-4">lojas</h4>
                </a>
              </div>
            </div>
            <div className="col-lg-3 mb-lg-0 mb-3">
              <div className="oportunidades">
                <a href="https://diabrasil.pandape.com.br/" target="_blank" rel="noopener noreferrer">
                  <h4 className="text-uppercase text-red text-center mt-4 mb-4">centros de distribuição</h4>
                </a>
              </div>
            </div>
            {/* <div className="col-lg-3 mb-lg-0 mb-3">
              <div className="oportunidades">
                <a href="https://diabrasil.pandape.com.br/" target="_blank" rel="noopener noreferrer">
                  <h4 className="text-uppercase text-red text-center mt-4 mb-4">administrativo</h4>
                </a>
              </div>
            </div> */}
            <div className="col-lg-3 mb-lg-0 mb-3">
              <div className="oportunidades">
                <a href="https://diabrasil.pandape.com.br/" target="_blank" rel="noopener noreferrer">
                  <h4 className="text-uppercase text-red text-center mt-4 mb-4">corporativo</h4>
                </a>
              </div>
            </div>
            <div className="col-lg-12 col-10 mt-lg-5 mt-2 d-flex justify-content-center" style={{ minheight: '45px' }}>
              <a href="https://diabrasil.pandape.com.br/" target="_blank" rel="noreferrer"><button id='button-darkmode' className="btn btn-red-1 w-auto h-100 font-size-13 px-4 py-2">Veja todas as oportunidades</button></a>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`{
  img1: file(relativePath: {eq: "carreiras/image1.jpeg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  img2: file(relativePath: {eq: "carreiras/image2.jpeg"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
  imgValoresDia: file(relativePath: {eq: "carreiras/Valores_DIA.png"}) {
    childImageSharp {
      gatsbyImageData(quality: 100, layout: FULL_WIDTH)
    }
  }
}
`
